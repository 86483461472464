export enum PriceSource {
  GQ = 'GQ',
  SAP_STANDARD = 'SAP_STANDARD',
  SAP_SPECIAL = 'SAP_SPECIAL',
  CAP_PRICE = 'CAP_PRICE',
  STRATEGIC = 'STRATEGIC',
  MANUAL = 'MANUAL',
  TARGET_PRICE = 'TARGET_PRICE',
  SECTOR_DISCOUNT = 'SECTOR_DISCOUNT',
  END_CUSTOMER_DISCOUNT = 'END_CUSTOMER_DISCOUNT',
  ZKI1 = 'ZKI1',
}
