<div
  class="flex h-full flex-col"
  *transloco="let t; read: 'shared.editingMaterialModal'"
>
  <gq-dialog-header [title]="t('title')" (closeDialog)="closeDialog()">
  </gq-dialog-header>
  <form [formGroup]="editFormGroup" class="mt-6 flex h-full flex-col">
    <div
      class="flex flex-col"
      *ngrxLet="
        isCaseView
          ? customerIdentifierForCaseCreation$
          : customerIdentifierForActiveCase$ as customerIdentifier
      "
    >
      <div class="flex flex-row gap-6">
        @if (materialDescForEditMaterial$ | ngrxPush; as materialDesc) {
          <div class="flex-1">
            <gq-autocomplete-input
              #materialDescInput
              [showFieldHint]="false"
              [filterName]="materialDesc.filter"
              [fitContent]="true"
              [options]="materialDesc.options"
              [autocompleteLoading]="
                materialDescAutocompleteLoading$ | ngrxPush
              "
              (autocomplete)="autocomplete($event, customerIdentifier)"
              (inputContent)="materialHasInput($event)"
              (isValid)="materialInputValid($event)"
              (unselected)="autocompleteUnselectOptions(materialDesc.filter)"
              (added)="
                autocompleteSelectMaterialNumberDescriptionOrCustomerMaterial(
                  $event,
                  materialDesc.filter
                )
              "
            ></gq-autocomplete-input>
          </div>
        }
        @if (materialNumberForEditMaterial$ | ngrxPush; as materialNumber) {
          <div class="flex-1">
            <gq-autocomplete-input
              #materialNumberInput
              [filterName]="materialNumber.filter"
              [fitContent]="true"
              [options]="materialNumber.options"
              [autocompleteLoading]="
                materialNumberAutocompleteLoading$ | ngrxPush
              "
              (isValid)="materialInputValid($event)"
              (autocomplete)="autocomplete($event, customerIdentifier)"
              (inputContent)="materialHasInput($event)"
              (unselected)="autocompleteUnselectOptions(materialNumber.filter)"
              (added)="
                autocompleteSelectMaterialNumberDescriptionOrCustomerMaterial(
                  $event,
                  materialNumber.filter
                )
              "
            ></gq-autocomplete-input>
          </div>
        }
        @if (isNewCaseCreation) {
          <div
            *ngrxLet="customerMaterialForEditMaterial$ as customerMaterial"
            class="flex-1"
          >
            <gq-autocomplete-input
              #customerMaterialInput
              [showFieldHint]="false"
              [filterName]="customerMaterial.filter"
              [fitContent]="true"
              [options]="customerMaterial.options"
              (unselected)="
                autocompleteUnselectOptions(customerMaterial.filter)
              "
              (added)="
                autocompleteSelectMaterialNumberDescriptionOrCustomerMaterial(
                  $event,
                  customerMaterial.filter
                )
              "
              [autocompleteLoading]="
                customerMaterialAutocompleteLoading$ | ngrxPush
              "
              (inputContent)="customerMaterialHasInput()"
              (autocomplete)="autocomplete($event, customerIdentifier)"
              [maxLength]="CUSTOMER_MATERIAL_MAX_LENGTH"
            ></gq-autocomplete-input>
          </div>
        }
      </div>
      <div class="mt-4 flex flex-row gap-6">
        <div
          class="flex-1"
          *ngrxLet="
            selectedMaterialAutocomplete$ | ngrxPush as selectedMaterial
          "
        >
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>
              {{ t('quantity.label') }}
            </mat-label>

            <input
              class="text-overline"
              autocomplete="off"
              matInput
              #valueInput
              [min]="(isNewCaseCreation && selectedMaterial?.deliveryUnit) || 1"
              [step]="
                (isNewCaseCreation && selectedMaterial?.deliveryUnit) || 1
              "
              type="number"
              formControlName="quantity"
              (keydown)="handleQuantityKeyDown($event)"
              [attr.data-cy]="'edit-material-quantity'"
            />

            @if (!isNewCaseCreation) {
              <mat-hint>{{ t('quantity.hint') }}</mat-hint>
            }
            @if (selectedMaterial?.deliveryUnit && isNewCaseCreation) {
              <mat-hint>{{
                t('quantity.hint', {
                  unit: selectedMaterial.deliveryUnit,
                  uom: selectedMaterial.uom | uom
                })
              }}</mat-hint>
            }

            @if (
              isNewCaseCreation &&
              (editFormGroup.get('quantity').hasError('invalidDeliveryUnit') ||
                editFormGroup.get('quantity').hasError('min'))
            ) {
              <mat-error>{{
                selectedMaterial?.deliveryUnit
                  ? t('quantity.errorDeliveryUnit', {
                      unit: selectedMaterial.deliveryUnit,
                      uom: selectedMaterial.uom | uom
                    })
                  : t('quantity.error')
              }}</mat-error>
            }
            @if (editFormGroup.get('quantity')?.errors?.required) {
              <mat-error>{{ t('quantity.error') }}</mat-error>
            }
          </mat-form-field>
        </div>
        <ng-container
          ><div class="flex-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>
                {{ t('targetPrice.label') }}
              </mat-label>

              <input
                class="text-overline"
                autocomplete="off"
                matInput
                #targetPriceInput
                type="text"
                formControlName="targetPrice"
                [attr.data-cy]="'edit-material-targetPrice'"
              />

              @if (editFormGroup.get('targetPrice')?.errors?.invalidPrice) {
                <mat-error>{{ t('targetPrice.error') }}</mat-error>
              }
            </mat-form-field>
          </div></ng-container
        >
        @if (isNewCaseCreation) {
          <gq-target-price-source-select
            #targetPriceSourceInput
            class="flex-1"
            [appearance]="'outline'"
            formControlName="targetPriceSource"
          ></gq-target-price-source-select>
        }
      </div>
    </div>
  </form>

  <div class="pt-8">
    <button
      class="!pl-4 !pr-4"
      color="primary"
      mat-raised-button
      (click)="update()"
      [disabled]="!updateRowEnabled"
      [attr.data-cy]="'edit-material-update_btn'"
    >
      <span> {{ t('update') | uppercase }} </span>
    </button>
    <button
      class="!ml-4 !pl-4 !pr-4"
      color="primary"
      (click)="closeDialog()"
      mat-stroked-button
      [attr.data-cy]="'edit-material-cancel_btn'"
    >
      <span> {{ t('cancel') | uppercase }} </span>
    </button>
  </div>
</div>
