@if (filterName) {
  <mat-form-field
    #formField
    class="w-full"
    appearance="outline"
    *transloco="let t; read: 'shared.caseMaterial.addEntry'"
    [ngClass]="{ 'error-quotation': !isValid }"
  >
    <mat-label class="error-quotation-label">{{
      t(filterName + '.label')
    }}</mat-label>
    <input
      [formControl]="searchFormControl"
      (paste)="onPaste($event)"
      matInput
      #valueInput
      [matAutocomplete]="auto"
      cdkFocusInitial
      [maxlength]="maxLength"
      pinDropDown="matAutocompleteTrigger"
      [attr.data-cy]="'autocomplete-' + filterName + '_input'"
    />
    @if (autocompleteLoading) {
      <mat-spinner matSuffix [diameter]="18"></mat-spinner>
    }
    @if (showFieldHint) {
      <mat-hint>{{ t(filterName + '.hint') }}</mat-hint>
    }
    @if (maxLength) {
      <mat-hint align="end">{{
        (searchFormControl.value?.length?.toString() || '0') +
          ' / ' +
          maxLength.toString()
      }}</mat-hint>
    }
    @if (searchFormControl.hasError('invalidInput')) {
      <mat-error>{{ t(filterName + '.wrongInput') }}</mat-error>
    }
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      @for (idValue of unselectedOptions; track $index) {
        <mat-option [value]="idValue">
          <span> {{ idValue.id }} | {{ idValue.value }}</span>
          @if (idValue.value2 && !MATERIAL_FILTERS?.includes(filterName)) {
            <span> | {{ idValue.value2 }}</span>
          }
        </mat-option>
      }
      @if (
        unselectedOptions
          | noResultsFoundPipe
            : selectedIdValue
            : searchFormControl.value
            : autocompleteLoading
            : debounceIsActive
      ) {
        <mat-option disabled>
          {{ t(filterName + '.nothingFound') }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
